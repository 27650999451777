/* office-ui theme variables:
primary: #3fa9f5
text: #ffffff
background: #262525
*/

html,
body {
    height: 100vh;
    margin: 0;
    padding: 0;
    /* background-color: #262525;
    color: #ffffff; */
}

#root {
    height: 100vh;
}

.noInit {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: red;
}

.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.app-header {
    display: flex;
    justify-content: flex-start;
    top: 0;
    width: 100%;
    border-bottom: 1px solid white;
    min-height: 80px;
    height: 80px;
}

.app-header-logo {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 150px;
}

.app-header-product {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    padding: 10px;
    align-items: center;
    width: 400px;
    font-size: 1.6rem;
}

.app-header-right {
    display: flex;
    height: 100%;
    flex: auto;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
}

.app-sidebar-page {
    width: 100%;
    flex: auto;
    /* height: 400px; */
    display: flex;
    flex-direction: row;
    /* overflow-y: auto; - let it scroll for a better UI experience */
}

.app-sidebar {
    display: flex;
    min-width: 250px;
    width: 250px;
    height: 100%;
    border-right: 1px solid white;
}

.app-sidebar-wrapper {
    width: 100%;
    padding-top: 10px
}

.app-page {
    width: 100%;
    /* this sets variable height to 100% */
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: space-between;
}

.app-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.app-page-error {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    justify-content: center;
}

.app-footer {
    display: flex;
    align-items: center;
    height: 30px;
    min-height: 30px;
    width: 100%;
    border-top: 1px solid gray;
    font-size: 0.75rem;
    letter-spacing: 0.065rem;
}

.app-footer > .cr {
    margin-left: 10px;
}

.app-footer > .ws {
    margin-left: 10px;
}

.app-footer > .ws a {
    color: #4DB7FF
}

.app-footer > .ver {
    margin-right: 10px;
}

.app-footer > .ver {    
    display: flex;
    justify-content: flex-end;
    flex: 1 0 auto;
}

.w-100 {
    width: 100%;
}

div.page-form {
    margin-top:10px; 
    margin-left:20px;
    margin-right: 20px;
}

/* div.page-header {
    padding-top:5px;
    padding-bottom:5px;
} */

div.page-content {
    margin-top:10px; 
    margin-left:32px;
    margin-right: 20px;
}

div.user-menu {
    display: flex;
    align-items: center;
}

/* .grecaptcha-badge { 
    visibility: hidden; 
} */

/* .ms-DatePicker-table td {
    color: white;
 }

 .ms-DatePicker-header div {
    color: white;
 }

 th.ms-DatePicker-weekday {
    color: white;
 }

 button.ms-DatePicker-prevMonth {
    color: white;    
 }

 button.ms-DatePicker-nextMonth {
    color: white;    
 }

.ms-DatePicker-table td[class*=dayIsHighlighted][class*=daySelection] {
    background-color:#605e5c !important; 
} */


/* .ms-DatePicker-table td[class*=dayIsHighlighted][class*=daySelection] {background-color: red !important} */